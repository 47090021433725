import React from 'react';
import YouTube from 'react-youtube';

const GalleryContent  = ({classes}) => {
    const opts = {
        height: '280',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
    }
    return (
            <section className={`work-section ${classes}`} id="work">
                <div className="container">
					{/* End: Heading */}
                    <div className="projects-list">  
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="jStK0oQ4PJg" key='1' opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="uTTYbggqDGo" key='2' opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="4QC6wVD8xYw" key='3'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="O3W7nlzg9bo" key='4'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="JBMvX93wAwA" key='5'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="dBONNW1aZw0" key='6'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="aBM6sUPfL_A" key='7'  opts={opts}/>
                            </div>
                        </div>



                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="c_p4wUAGnII" key='8'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="2dhS5jkWE18" key='9'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="FSChA-P9W6E" key='10'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="0ssMJzgM17M" key='11'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="65EkZJ3BTw8" key='12'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="08bi6GOdI40" key='13'  opts={opts}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <YouTube videoId="ND4wKnod-4E" key='14'  opts={opts}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

  );
};


export default GalleryContent;


