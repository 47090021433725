import React from 'react';   
import SectionTitle from "../../SectionTitles/SectionTitle";
 

const AboutContent = () => {

  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="about_list">
                        {/* Start: Heading */}
                        <SectionTitle 
                        title="Welcome to Navy Seals Moving "  
                        />
                        <p>Navy-Seals Moving is a family-owned Moving Company with over 20 years of experience, we are specialized in long-distance moves across the United States. Our business relies on a foundation of trust and solidarity that only family ties can bring, allowing us to put all our focus on our clients. We offer a full variety of services for any type of long-distance moves. Navy-Seals Moving is fully insured, bonded and licensed and operates out of our own warehouse facilities with climate control and security systems. We are not Brokers; we handle your move from the beginning to the end with our own fleet of trucks and our experienced team of movers. A happy customer is the building block of our success, we strive for excellence with every move we make.</p>
                        <p>Navy Seals Moving is one of the leading luxury as well as standard moving companies in NorthWest. Our story dates back to 2000s and since our humble beginnings, we have built our company into one of the most prestigious of its kind on the moving scene. On yearly basis, we move nearly 3000 clients. And, rest assured, no matter if we are enlisted with a standard moving package or the finest white glove moving service, the results we deliver have never failed to impress our moving clients. Further, for your protection, we have all licenses and credentials necessary for your real peace of mind.</p>
                     </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img src={process.env.PUBLIC_URL + '/assets/images/about.jpg'} alt="" />

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutContent;
