import React from 'react';
import YouTube from 'react-youtube';
import {Link} from "react-router-dom"; 
import SectionTitle from "../SectionTitles/SectionTitle";

const GalleryContentShort  = ({classes}) => {
    const opts = {
        height: '280',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
    }
    return (
            <section className={`mini-gallery-section ${classes}`} id="work">
                <div className="container">
                    <div className="row"> 
                            {/* Heading */}
                            <div className="more-about-us col-md-8 col-sm-12" >
                                <SectionTitle
                                    title="More About Us"
                                />
                            </div>
                            {/* End: Heading */}

                            <div className="title-wrapper col-md-8 col-sm-12" >
                                <div className="team_video_title"> 
                                            <span className="why_chs_icon"><i className="arrow_carrot-down_alt"></i></span>
                                            <h5>Our Team And Storage</h5>
                                </div>  
                            </div>
                            <div className="row team-video"> 
                                <div className="col-md-6 col-sm-12">
                                    <video 
                                        src={process.env.PUBLIC_URL + '/assets/videos/sec-video.mp4'}
                                        controls = {true}
                                        type="video/mp4"
                                        className="video-item"
                                        poster={process.env.PUBLIC_URL + '/assets/images/sec-video-thumb.jpg'}
                                    />
                                </div>
                            </div>

                            <div className="title-wrapper col-md-8 col-sm-12" >
                                <div className="team_video_title"> 
                                            <span className="why_chs_icon"><i className="arrow_carrot-down_alt"></i></span>
                                            <h5>Customer Testimonials</h5>
                                </div>  
                            </div>
                            <div className="projects-list">  
                                <div className="col-md-4 col-sm-12">
                                    <div className="single-project-item">
                                        <YouTube videoId="jStK0oQ4PJg" key='1' opts={opts}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="single-project-item">
                                        <YouTube videoId="uTTYbggqDGo" key='2' opts={opts}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="single-project-item">
                                        <YouTube videoId="4QC6wVD8xYw" key='3'  opts={opts}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="single-project-item">
                                        <YouTube videoId="O3W7nlzg9bo" key='4'  opts={opts}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="single-project-item">
                                        <YouTube videoId="JBMvX93wAwA" key='5'  opts={opts}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="single-project-item">
                                        <YouTube videoId="dBONNW1aZw0" key='6'  opts={opts}/>
                                    </div>
                                </div>

                                <div className="slider_btn">
                                    <Link to={`${process.env.PUBLIC_URL + "/gallery"}`} className="slider_btn_one"> More Testimonials </Link>
                                </div>
                            </div>
                    </div>
                </div>
            </section>

  );
};


export default GalleryContentShort;


