import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { LinkWithQuery } from '../../helpers/LinkWithQuery';
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
                {/*  Start:About  */}
                <div className="col-lg-3 col-sm-12">
					<Widget title="Navy Seals Moving">
                        <p>Navy-Seals Moving is a family-owned Moving Company with over 20 years of experience, we are specialized in long-distance moves across the United States. We offer a full variety of services for any type of long-distance moves. Navy-Seals Moving is fully insured, bonded and licensed and operates out of our own warehouse facilities with climate control and security systems. We are not Brokers; we handle your move from the beginning to the end with our own fleet of trucks and our experienced team of movers. A happy customer is the building block of our success, we strive for excellence with every move we make.</p>
                    </Widget>
                </div> 
                {/*  End:About  */}

                {/*  Start:Quick Link  */} 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Helpful Links">
                        <List classes="recent-post helpful_post">
                            <LI>
                                <LinkWithQuery to={process.env.PUBLIC_URL + "/contact"}>Contact Us</LinkWithQuery>
                            </LI>
                            <LI>
                                <LinkWithQuery to={process.env.PUBLIC_URL + "/gallery"}>Watch Customer Reviews</LinkWithQuery>
                            </LI>
                            <LI>
                                <LinkWithQuery to={process.env.PUBLIC_URL + "/about"}>More About us</LinkWithQuery>
                            </LI> 
                        </List>
                    </Widget> 
                </div> 
                {/*  End:Quick Link  */}
 
                {/*  Start:Latest post   */}
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Our Locations">
                        <List classes="recent-post">
                            <LI>  
                                Seattle, WA
                            </LI>
                            <LI>  
                                Portland, OR
                            </LI>
                            <LI>  
                                Jersey City, NJ
                            </LI>
                            <LI>  
                                Charlotte, NC
                            </LI>
                        </List>
                    </Widget> 
                </div> 
                {/*  End:Latest post  */}


                {/*  Start:Newsletter  */} 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Social Media">
                        {/*  Start:Subscribe  */}
                    <div className="footer_social_colorful"> 
                        <ul className="footer_social_icons">
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/cUrKYLa1WfEjfajX8"><i className="fa fa-google"></i></a>  </li>
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.yelp.ca/biz/navy-seals-moving-tukwila"> <i className="fa fa-yelp"></i></a></li>
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@navysealsmoving7695/videos"><i className="fa fa-youtube"></i></a></li>
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100066631421741"><i className="fa fa-facebook"></i></a></li>
                        </ul>
                    </div>
                    </Widget> 
                </div> 
                {/*  End:Start Subscribe  */} 
 

                {/*  Start:Subfooter  */}
                <div className="subfooter"> 
                    <div className="copyright_text"> &copy; {new Date().getFullYear()} Navy Seals Moving
                    </div> 

                    {/* SOCIAL ICON */} 
                    <div className="footer_social"> 
                        <ul className="footer_social_icons">
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/cUrKYLa1WfEjfajX8"><i className="fa fa-google"></i></a>  </li>
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.yelp.ca/biz/navy-seals-moving-tukwila"> <i className="fa fa-yelp"></i></a></li>
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@navysealsmoving7695/videos"><i className="fa fa-youtube"></i></a></li>
                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100066631421741"><i className="fa fa-facebook"></i></a></li>
                        </ul>
                    </div>

                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <img src={process.env.PUBLIC_URL + '/assets/images/arrow.png'} alt="" />
                    </button> 
                </div> 
                {/* End:Subfooter  */}

            </div>
        </div> 

    </footer> 

        )
 
}

 
export default Footer;