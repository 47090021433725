import React,{Component} from 'react'; 
import axios from "axios";
import {Helmet} from "react-helmet";
class EstimateForm extends Component{

    constructor(){
        super();

        const urlParams = new URLSearchParams(window.location.search);
        // stringify all query params
        const paramPairs = []
        urlParams.forEach((value, key) => {
          paramPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        });
        const paramString = paramPairs.join('&');

        this.state={ keyword:paramString,email:'',name:'',phone:'',message:'', from:'', to:'', email_err:'', name_err:'', phone_err:'', from_err:'', to_err:'', message_err:'',return_msg:'',flag : false }

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeFrom = this.handleChangeFrom.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleSubmit1 = this.handleSubmit1.bind(this);
    }

    handleChangeEmail(e){
        this.setState({email:  e.target.value});
        var EmailReg =  /^(.+)@(.+)$/;
        if(e.target.value === '') 
          this.setState({email_err: 'Required Field'});
        else if(EmailReg.test(e.target.value)) 
          this.setState({email_err: ''});
        else 
          this.setState({email_err: 'Enter Valid Email'});
      }

      handleChangeName(e){
        this.setState({name:  e.target.value});
          if(e.target.value === '') 
            this.setState({name_err: 'Required Field'});
          else 
            this.setState({name_err: ''});
      }
      handleChangePhone(e){
        this.setState({phone:  e.target.value});
          if(e.target.value === '') 
            this.setState({phone_err: 'Required Field'});
          else 
            this.setState({phone_err: ''});
      }
      handleChangeFrom(e){
        this.setState({from:  e.target.value});
          if(e.target.value === '') 
            this.setState({from_err: 'Required Field'});
          else 
            this.setState({from_err: ''});
      }
      handleChangeTo(e){
        this.setState({to:  e.target.value});
          if(e.target.value === '') 
            this.setState({to_err: 'Required Field'});
          else 
            this.setState({to_err: ''});
      }
      handleChangeMessage(e){
        this.setState({message:  e.target.value});
      }

      handleSubmit1()
      {
        if(this.state.name === '') 
            this.setState({name_err: 'Required Field'});
        if(this.state.email === '')
            this.setState({email_err: 'Required Field'});
        if(this.state.phone === '')
            this.setState({phone_err: 'Required Field'});
        if(this.state.from === '')
            this.setState({from_err: 'Required Field'});
        if(this.state.to === '')
            this.setState({to_err: 'Required Field'});

        if(this.state.name === '' || this.state.email === '' || this.state.phone === '')
        {
          this.setState({return_msg: 'Please Fill All First', flag: true });
        }
        else
        {
          axios
            .post(process.env.PUBLIC_URL + "/api/submit/estimate",this.state)
            .then(response => {
              console.log(response)
              this.setState({return_msg: 'The request has been submitted. Thank you for contacting us!', flag: true});
            })
            .catch((err) => {
              this.setState({return_msg: 'Unfortunately an error has occurred. We are sorry for the inconvenience and appreciate if you can contact us by phone. ', flag: true});
              console.log(err)
            });
        }
      }
    render(){
    return(
  
            <div className="quote-form">
              <div id="message">
                  {this.state.flag ? 
                        <div className="alert alert-success">
                        <strong>{this.state.return_msg}</strong>
                        </div>
                    : null}
                  {this.state.flag ? 
                        <div className="form-submit-quote-tag">
                        <Helmet>
                          <script>
                            gtag_report_conversion ();
                          </script>
                        </Helmet>
                      </div>
                    : null}
              </div> 
              <form method="post" id="quoteForm">
                  <div className="row">
                  <div className="col-md-6 col-sm-12">
                      <div className="input-group"> 
                          <span id="err">{this.state.name_err}</span>
                          <input type="text" id="name" value={this.state.name} onChange={this.handleChangeName} className="form-control" placeholder="Name:" /> 
                      </div>
                      <div className="input-group"> 
                          <span id="err">{this.state.email_err}</span>
                          <input type="text" className="form-control" value={this.state.email} onChange={this.handleChangeEmail} id="email" placeholder="Email:" />
                      </div>                            
                      <div className="input-group"> 
                          <span id="err">{this.state.phone_err}</span>
                          <input type="text" id="tel" value={this.state.phone} onChange={this.handleChangePhone} className="form-control" placeholder="Phone:" />
                      </div> 
                      <div className="input-group"> 
                          <span id="err">{this.state.from_err}</span>
                          <input type="text" id="from" value={this.state.from} onChange={this.handleChangeFrom} className="form-control" placeholder="From Address (or Zip Code):" />
                      </div> 
                      <div className="input-group"> 
                          <span id="err">{this.state.to_err}</span>
                          <input type="text" id="to" value={this.state.to} onChange={this.handleChangeTo} className="form-control" placeholder="To Address (or Zip Code):" />
                      </div> 
                  </div> 
                  <div className="form-submit-group col-md-6 col-sm-12">
                      <div className="input-group input_group_full_width"> 
                          <span id="err">{this.state.message_err}</span>
                          <textarea name="comments" id="message" value={this.state.message} onChange={this.handleChangeMessage} className="form-control" placeholder="Message (Optional)"></textarea>
                      </div> 
                      <div className="col-sm-12">
                    <button type="button" name="send" onClick={this.handleSubmit1} className="submit-button btn btn-chos" value="Submit">Submit</button>
                    <div id="simple-msg"></div>
                  </div>
                  </div> 
              </div> 
              </form> 
            </div> 

        )

    }
}

 
export default EstimateForm;