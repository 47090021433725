import React from "react";  


const Titles = () => {
  const currentUrl = window.location.href;
  let isLocal = false
  if (currentUrl.includes("local.navysealsmoving")) {
    isLocal = true;
  }
  return (
    
	<section 
    className="titles" id="quote" >
	    <div className="container"> 
			<div className="row">  
				<div className="col-md-8 col-sm-12 titles_sec">
					{/* Heading */}
					<div>
						{!isLocal && <h1>Your Trusted Long Distance Movers</h1>}
						{!isLocal && <h2>Nationwide Moving from Washington State to any destination</h2>  }
						{isLocal && <h1>Your Trusted Specialists for Local and Long-Distance Moving</h1>}
						{/* isLocal && <h2>Nationwide Moving from Washington State to any destination</h2> */ }
						{/* <h3>Best Price Guarantee</h3>   */}
					</div>
					{/* End: Heading */}
				</div> 
			</div> 
        </div> 
	</section>  

  );
};

export default Titles;
