import React from 'react';
 

const Header = () => {
	return (
 
    <div className="header_top_area">
        <div className="container">
            <div className="row">
                {/* Address / Phone / Email*/}
                <div className="colm">
                    <div className="header_wrapper_1">
                        <ul>
                            <li> <i className="icon_mail_alt"></i>info@navysealsmoving.com</li>
                            <li>Washington | Oregon | New York | New Jersey | North Carolina</li> 
                        </ul>
                    </div>
                    <div className="call_social">
                        <a href="tel:2066366961">
                            <div className="call_us_now">
                                <ul>
                                <li>CALL US NOW</li>
                                <li><i className="icon_phone"></i> (206) 636-6961</li>
                                </ul>
                            </div>
                        </a>
                        <div className="header_social"> 
                            <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/cUrKYLa1WfEjfajX8">
                                <i className="fa fa-google"></i>
                            </a> 
                            <a target="_blank" rel="noopener noreferrer" href="https://www.yelp.ca/biz/navy-seals-moving-tukwila">
                                <i className="fa fa-yelp"></i>
                            </a> 
                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@navysealsmoving7695/videos">
                                <i className="fa fa-youtube"></i>
                            </a> 
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100066631421741">
                                <i className="fa fa-facebook"></i>
                            </a> 
                        </div>
                    </div>
                </div>

                {/* SOCIAL ICON */}
                {/* <div className="colm">

                </div> */}
            </div>
        </div>
    </div>
 
	)
}


export default Header;