import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Titles from '../components/Titles'
import WhyChooseUs from '../components/WhyChooseUs'
import Video from '../components/Video'
import GalleryShort from '../components/Gallery/GalleryShort';

const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Navy Seals Moving | Home</title>
        <meta
          name="description"
          content="Navy Seals Moving."
        />
      </MetaTags>
      <LayoutOne>
        <Titles />
        <Video />
        <WhyChooseUs />
        <GalleryShort />
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
